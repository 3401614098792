<template>
  <div>
    <page-header title="选择城市" />
    <div class="city-select">
      <van-search v-model="value"
                  placeholder="请输入城市名称筛选"
                  show-action
                  @search="searchConfirm"
                  @cancel="onCancel">
        <template #action>
          <div @click="searchConfirm(value)">搜索</div>
        </template>
      </van-search>
      <!-- <div style="height: 1.1rem"></div> -->
      <!-- 搜索列表 -->
      <div class="searchList"
           v-if="searchVal">
        <div class="search-item"
             v-for="(item, index) in cityList"
             :key="index"
             @click="handleSelect(item)">
          {{ item.name }}
        </div>
      </div>
      <!-- 城市列表 -->
      <van-index-bar v-else
                     :index-list="indexList"
                     :sticky-offset-top="stickyOffsetTop"
                     highlight-color="#FFB500">
        <div v-for="(letter, idx) in cityList"
             :key="idx">
          <van-index-anchor :index="idx"></van-index-anchor>
          <van-cell v-for="(item, index) in letter"
                    :key="index"
                    title-width="4rem"
                    :title="item.name"
                    @click="handleSelect(item)" />
        </div>
      </van-index-bar>
      <!-- 空列表 -->
      <!-- <yi-search-empty v-if="isEmpty"></yi-search-empty> -->
    </div>
  </div>
</template>

<script>
import * as service from "@/api/area.ts";
import { setUserCity } from "@/utils/StorageHelper.ts";
export default {
  name: "CitySelect",
  components: {},
  data () {
    return {
      cityList: "",
      cityList2: [],
      show: false,
      indexList: [],
      flatList: [],
      searchVal: "",
      columns: [],
      isSelect: false,
      currentCity: {},
      // 是否已发起过搜索, 防止一进来就显示空页面
      isSearchReady: false,
      stickyOffsetTop: 0,
      value: "",
    };
  },
  computed: {
    isEmpty () {
      if (this.cityList && Array.isArray(this.cityList)) {
        return this.cityList.length === 0;
      } else if (typeof this.cityList === "object" && this.cityList !== null) {
        return Object.keys(this.cityList).length === 0;
      } else if (this.isSearchReady) {
        // 只有当进行过请求没有数据时, 才展示空页面
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    cancel () {
      this.isSelect = false;
    },
    confirm (e) {
      this.cancel();

      const city = {};
      city.cityCode = e.code;
      city.cityName = e.name;
      setUserCity(JSON.stringify(city));
      //todo 回退到上一个页面
      this.$router.go(-1); //返回上一层
    },
    searchConfirm (value) {
      console.log("确认搜搜城市", value)
      if (!value) return;
      this.cityList = [];
      this.searchVal = value;
      for (let item of this.flatList) {
        if (item.name.indexOf(value) !== -1) {
          this.cityList.push(item);
        }
      }
    },
    searchInput (value) {
      if (!value) {
        this.searchVal = value;
        this.cityList = this.cityList2;
      }
    },
    async initData () {
      console.log("初始化城市列表");
      let resData = await service.getCityList();
      this.isSearchReady = true;
      if (resData.code === 200) {
        this.cityList = resData.data || [];
        this.cityList2 = JSON.parse(JSON.stringify(resData.data));
        this.indexList = Object.keys(this.cityList);
        for (let key in this.cityList) {
          this.flatList = this.flatList.concat(this.cityList[key]);
        }
      } else {
        this.cityList = [];
      }
    },
    handleSelect (item) {
      this.currentCity = item;
      this.confirm(item);
    },
    onCancel () {
      console.log("点击了取消按钮")
    }
  },
  created () {
    this.isSearchReady = false;
    this.initData();
  },
};
</script>

<style lang="less" scoped>
.city-select {
  width: 100%;
  padding-top: 0.88rem;
  .saerch-fixed {
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;
  }
  .van-index-anchor {
    padding-left: 0.32rem;
    box-sizing: border-box;
    background-color: #f5f5f5;
  }
  .searchList {
    width: 100%;
    .search-item {
      width: 100%;
      height: 0.88rem;
      line-height: 0.88rem;
      box-sizing: border-box;
      padding-left: 0.32rem;
    }
  }
}
</style>
